import * as React from "react"
import Layout from "../components/layout";
import {graphql, Link} from "gatsby";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {RichText} from "prismic-reactjs";
import IconLink from "../components/icon-link";
import GatsbyLink from "../components/gatsby-link";
import PieChart from "../components/piechart";
import {useState} from "react";
import {useInView} from "react-intersection-observer";
import Image from "react-bootstrap/Image";

const LeistungenPage = ({ data }) => {

  const [productsRef, productsInView, ] = useInView({
    threshold: 0.1,
    triggerOnce: true
  });

  const document = data.prismicLeistungenUbersicht.data;
  const products = data.allPrismicLeistungen.nodes;
  const phaseModel = document.phase_model;

  const [state, setState] = useState({
    activePhase: 'center',
  });

  const phaseSelected = (phase) => {
    setState({
      activePhase: phase
    });
  }

  return (
    <Layout title={RichText.asText(document.titel.raw)}>
      <section>
        <Row className="flex-md-row flex-column-reverse">
          <Col>
            <h1>
              {RichText.asText(document.titel.raw)}
            </h1>
            <RichText render={document.beschreibung.raw} serializeHyperlink={GatsbyLink}/>
          </Col>
        </Row>
      </section>
      <section ref={productsRef}>
        <Row className="justify-content-between mx-0">
          { products.map((product, index) => {
            return (
              <Col key={`product-${index}`} lg={4} md={6}
                   style={{backgroundColor: product.data.color}}
                   className={`mx-lg-1 mb-4 opacity-0 ${productsInView ? `post-it-slide-in-${index}`: ''} product-preview`}>
                <Link to={product.url}
                      className="text-center d-flex h-100 flex-column justify-content-between align-items-center">
                  <div className="product-preview-icon brush brush-contain brush-center">
                    <Image src={product.data.icon.url} alt={product.data.icon.alt} width={40} height={40} className="icon-img"/>
                  </div>
                  <h4>{RichText.asText(product.data.name.raw)}</h4>
                  <p className="small">{RichText.asText(product.data.kurzbeschreibung.raw)}</p>
                  <IconLink to={product.url} icon="FaArrowRight" size="sm" renderAnchor={false}>
                    {product.data.mehr_button}
                  </IconLink>
                </Link>
              </Col>
            );
          })}
        </Row>
      </section>
      <section className="mt-5 hyphens-auto">
        <Row>
          <Col>
            <RichText render={document.content.raw} serializeHyperlink={GatsbyLink}/>
          </Col>
        </Row>
        <Row className="mt-0 mt-md-3 mt-lg-5">
          <Col xs={12} lg={8} className="mb-0 mb-md-5 mr-0 mr-md-3">
            <div className="phasemodel-container d-flex flex-column align-items-center">
              <PieChart title={phaseModel.find(el => el.role === 'center').phasen_titel}
                        outer={phaseModel.find(el => el.role === 'outer').phasen_titel}
                        elements={phaseModel.filter(el => el.role !== 'center' && el.role !== 'outer').map(el => el.phasen_titel)}
                        activeKey={state.activePhase}
                        indexSelected={phaseSelected}/>
            </div>
          </Col>
          <Col className="text-left">
            <RichText render={phaseModel.find(el => el.role === state.activePhase).phasen_inhalt.raw} serializeHyperlink={GatsbyLink}/>
          </Col>
        </Row>
      </section>
      <section>
        <Row>
          <Col>
            <RichText render={document.uberleitung.raw} serializeHyperlink={GatsbyLink}/>
            <div className="d-flex flex-row justify-content-around">
              {document.body.map((node, index) => (
                <IconLink key={`link-${index}`} to={node.primary.link.url} icon={node.primary.icon}>{node.primary.link_label}</IconLink>
              ))}
            </div>
          </Col>
        </Row>
      </section>
    </Layout>
  )
}

export const query = graphql`
query MyQuery {
  prismicLeistungenUbersicht {
    data {
      content {
        raw
      }
      titel {
        raw
      }
      uberleitung {
        raw
      }
      beschreibung {
        raw
      }
      phase_model {
        role
        phasen_titel
        phasen_inhalt {
          raw
        }
      }
      body {
        ... on PrismicLeistungenUbersichtBodyIconLink {
          primary {
            icon
            link {
              url
            }
            link_label
          }
        }
      }
    }
  }
  allPrismicLeistungen(sort: {fields: data___order, order: ASC}) {
    nodes {
      uid
      url
      data {
        icon {
          alt
          url
        }
        color
        kurzbeschreibung {
          raw
        }
        name {
          raw
        }
        produktbeschreibung {
          raw
        }
        mehr_button
      }
    }
  }
}
`

export default LeistungenPage
