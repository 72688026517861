import * as React from "react";

import './piechart.scss';
import PropTypes from "prop-types"

const PieChart = ({ title, outer, elements, activeKey, indexSelected }) => {

  if (elements.length !== 4) {
    throw Error(`PieChart prop 'elements' must be an array of size 4 (is ${elements.length})`);
  }

  return (
    <div className="piechart-container">
      <div className={`piechart-outer ${activeKey === 'outer' ? 'active' : ''}`}
           role="button"
           tabIndex={0}
           onKeyDown={() => indexSelected("outer")}
           onClick={() => indexSelected("outer")}>
        <svg viewBox="0 0 550 550">
          <defs>
            <path id="curve-a" d="
            M 275,25
            a 250,250 0 1, 0 0,500" fillOpacity={0.5}/>
            <path id="curve-b" d="
            M 275,525
            a 250,250 0 1, 0 0,-500" fillOpacity={0.5}/>
          </defs>
          <text dy="17" dx="10%" textLength="1200">
            <textPath xlinkHref="#curve-a" className="small">
              {outer}
            </textPath>
          </text>
          <text dy="17" dx="10%" textLength="1200">
            <textPath xlinkHref="#curve-b" className="small">
              {outer}
            </textPath>
          </text>
        </svg>
      </div>
      <div className={`piechart-element piechart-top-right d-flex flex-row justify-content-center align-items-center ${activeKey === '1' ? 'active' : ''}`}
           role="button"
           tabIndex={0}
           onKeyDown={() => indexSelected('1')}
           onClick={() => indexSelected('1')}>
        <div className="text-right">
          <h3>1</h3>
          <p>{elements[0]}</p>
        </div>
      </div>
      <div className={`piechart-element piechart-bottom-right d-flex flex-row justify-content-center align-items-center ${activeKey === '2' ? 'active' : ''}`}
           role="button"
           tabIndex={0}
           onKeyDown={() => indexSelected('2')}
           onClick={() => indexSelected('2')}>
        <div className="text-right">
          <h3>2</h3>
          <p>{elements[1]}</p>
        </div>
      </div>
      <div className={`piechart-element piechart-bottom-left d-flex flex-row justify-content-center align-items-center ${activeKey === '3' ? 'active' : ''}`}
           role="button"
           tabIndex={0}
           onKeyDown={() => indexSelected('3')}
           onClick={() => indexSelected('3')}>
        <div className="text-left">
          <h3>3</h3>
          <p>{elements[2]}</p>
        </div>
      </div>
      <div className={`piechart-element piechart-top-left d-flex flex-row justify-content-center align-items-center ${activeKey === '4' ? 'active' : ''}`}
           role="button"
           tabIndex={0}
           onKeyDown={() => indexSelected('4')}
           onClick={() => indexSelected('4')}>
        <div className="text-left">
          <h3>4</h3>
          <p>{elements[3]}</p>
        </div>
      </div>
      <div className={`piechart-center d-flex flex-row justify-content-center align-items-center ${activeKey === 'center' ? 'active' : ''}`}
           role="button"
           tabIndex={0}
           onKeyDown={() => indexSelected("center")}
           onClick={() => indexSelected("center")}>
        <h3>{title}</h3>
      </div>
    </div>
  );
}

PieChart.propTypes = {
  title: PropTypes.string.isRequired,
  outer: PropTypes.string.isRequired,
  elements: PropTypes.arrayOf(PropTypes.string).isRequired,
  activeKey: PropTypes.string.isRequired,
  indexSelected: PropTypes.func.isRequired
}

PieChart.defaultProps = {
}

export default PieChart
